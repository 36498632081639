<template>
  <!--  <pre>{{ vv.$errors }}</pre>-->
  <div class="main">
    <div class="field-title">{{ title }}</div>
    <div v-if="!bEdit" v-on:click="bEdit=true"
         v-bind:style="{cursor: selectedCursor}"
         class="content">
      {{ getValue() || 'Click para editar' }}
    </div>
    <!-- Comercializador -->
    <div v-if="selectedRoleId===6 && bEdit">
      <div class="flex justify-content-center">
        <Dropdown v-model="content"
                  :options="loterias"
                  optionValue="code"
                  optionLabel="label"
                  placeholder="Seleccionar Loteria"
                  :onClick="refreshLoteria"
                  class="drop"
                  :loading="loading"
                  :showClear="true"
                  scrollHeight="400px"
                  :onChange="setEntityId"
                  style="width: 90%; cursor: default"/>
        <button
            class="p-button p-component p-button-icon-only"
            type="button"
            v-if="bEdit"
            v-on:click="bEdit=false"
        >
          <span class="pi pi-times p-button-icon"></span>
          <span class="p-button-label">&nbsp;</span>
          <span class="p-ink" style="height: 33px; width: 33px; top: 2.8125px; left: -5.14062px;"></span>
        </button>
      </div>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>
  </div>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {required, helpers} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ref} from "vue";

export default {
  name: "EntityFilter",
  props: {
    selectedRoleId: Number,
    entity_id: Number,
    selectedInput: {
      type: Number,
    },
    nonEmptyField: String,
    title: String,
    input: {
      type: Number,
    },
  },
  setup(props) {
    const content = ref(props.entity_id);//ref(this.content);//ref(88)
    const mandatoryField = 'Campo obligatorio';
    const rules = {
      content: {
        required: helpers.withMessage(mandatoryField, required),
        $autoDirty: true
      },
    }
    const vv = useVuelidate(rules, {content})
    return {content, vv}
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.theDate = this.getYesterdayString()
    this.refreshLoteria();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
    // this.bEdit = this.identity.entityId === null;
    this.entityId = this.selectedInput;
  },
  data() {
    return {
      selectedLoteria: null,
      selectedEntityId: null,
      loterias: null,

      loading: false,
      bEdit: false,
      identity: null,
    }
  },
  methods: {
    async refreshLoteria() {
      this.loading = true;
      this.selectedLoteria = null;
      this.loterias = await this.mainService.getLoteria({});
      this.loading = false;
    },
    setEntityId(event) {
      // this.entity_id = this.selectedEntityId;
      this.$emit('setEntityId', event)
    },
    getValue() {
      this.loading = true;
      let response = null;
      // Loteria
      if (this.loterias && this.selectedRoleId === 6 && this.entity_id) {
        let result = this.loterias.filter(item => item.code === this.entity_id);
        response = result[0].label;
      } else {
        response = 'Click para editar'
      }
      this.loading = false;
      return response;
    }
  },
}
</script>

<style scoped>
.main {
  /*padding: 10px;*/
  margin: 10px;
}

.field-title {
  color: var(--green-banklot);
  font-weight: bold;
  margin-bottom: 10px;
}

.content {
  margin-left: 10px;
}

.drop {
  height: 33px;
}
</style>