<template>
  <div id="overlay" v-if="overlay"></div>
  <Dialog
      :style="{width: '40vw'}"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">

    <div id="overlay" v-if="overlay"></div>
    <template #header>
      <h3>Usuario: {{ user && user.name }}  {{selectedRoleId}}</h3>
    </template>

    <div class="form">
<!--      <pre>{{ vv.$errors }}</pre>-->
      <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">
        <div class="grid">

          <div class="col-12 lg:col-6 xl:col-6">
            <!--        Name -->
            <EditLabel ref="name" :input="user.name" title="Nombre" :required="true" />
            <!--        Email -->
            <EditLabel ref="email" :input="user.email" title="Correo Electrónico" :required="true" :email="true" />
            <!--        Password-->
            <EditLabel ref="password" :input="user.password" title="Contraseña" />
          </div>
          <div class="col-12 lg:col-6 xl:col-6">
            <!--        Nivel de Acceso-->
            <EditDropdownGrouped
                ref="role_id"
                :options="getConalotUserRoles(null, identity.entityId)"
                optionLabel="label"
                optionValue="code"
                :selectedInput="selectedRoleId"
                @onChange="onChangeRoleId"
                title="Nivel de Acceso"
            />
            <!--        Entidad -->
            <EntityFilter
                ref="entity_id"
                v-if="selectedRoleId === 6"
                :selectedRoleId="Number(selectedRoleId)"
                :entity_id="selectedEntityId"
                @setEntityId="onChangeEntityId"
                title="Entidad"
            />

          </div>

        </div>
        <!--        Submit button-->
        <!--        <Button type="button" @click="vv.$touch()">$touch</button>-->
        <div class="flex justify-content-end">
          <Button
              class="p-button-raised" icon="pi pi-save"
              type="submit"
              @click="vv.$touch()"
              :disabled="vv.$errors[0] || !vv.$anyDirty"
          />
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import generalMixin from "@/mixins/generalMixin";
import UserConalotService from "@/service/backend/UserService";
import EditLabel from "@/components/misc/Labels/EditLabel";
import EntityFilter from "@/components/Settings/Users-Conalot/Dialog/EntityFilter.vue";
import EditDropdownGrouped from "@/components/misc/Labels/EditDropdownGrouped.vue";


export default {
  props: {
    show: Boolean,
    user: Object,
  },
  components: {
    EditDropdownGrouped,
    EntityFilter,
    EditLabel,
  },
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale = {accept: 'Si', reject: 'No'};
    this.userService = new UserConalotService();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  setup() {
    let vv = useVuelidate()
    return {vv};
  },
  data() {
    return {
      action: 'Crear',
      submitted: false,
      showMessage: false,
      showPassword: false,

      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      roleId: '',
      entityId: '',

      selectedRoleId: '',
      selectedStatusId: '',
      selectedEntityId: '',

      overlay: false,
      identity: null,
      bEdit: false,
    }
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      const user = {
        id: this.id,
        name: this.$refs.name.content,
        email: this.$refs.email.content,
        password: this.$refs.password.content,
        role_id: this.$refs.role_id.content,
        entity_id: this.$refs.entity_id.content,
      };
      this.saveUser(user);
    },
    loadForm(user) {
      this.action = 'Editar'
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.password = user.password;
      this.confirmPassword = user.password;

      this.selectedRoleId = user.role_id;
      this.selectedEntityId = user.entity_id;

      this.roleId = user.role_id;
      this.entityId = user.entity_id;

      this.showPassword = !this.id;
      this.submitted = false;
    },

    async saveUser(user) {
      try {
        this.overlay = true;
        // Encrypt password here
        user.password = user.password ? this.encodePassword(user.password) : user.password;

        if (user.id) {
          const response = await this.userService.updateConalot(user);
          if (response.error) {
            this.$toast.add({
              severity: 'error',
              summary: '',
              detail: 'No se pudo crear/editar el usuario | ' + response.error,
              life: 10000
            });
          } else if(response.id){
            this.$toast.add({
              severity: 'success',
              summary: '',
              detail: `Usuario ${user.name} editado.`,
              life: 3000
            });
          }
          this.userDialog = false;
          this.$emit('close-reload');
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo crear/editar el usuario | ' + e.message,
          life: 10000
        });
      }
      this.overlay = false;
    },
    async onChangeRoleId(roleId) {
      this.selectedRoleId = roleId.value;
      this.selectedEntityId = null;
    },
    async onChangeEntityId(entityId) {
      this.selectedEntityId = entityId.value;
      // this.selectedEntityId = null;
    },
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style lang="scss" scoped>

form {
  margin-top: 2rem;
}

.field {
  margin-bottom: 3rem;
}

.changePassword {
  top: -6px;
}

.form {
  margin-top: -30px;
}
</style>