<template>
  <div class="main">
    <div class="field-title">{{ title }}</div>
    <div v-if="!bEdit" v-on:click="bEdit=true"
         v-bind:style="{cursor: selectedCursor}"
         class="content">
      {{ getValue() || 'Click para editar' }}
    </div>

    <div class="flex justify-content-center">
      <Dropdown v-model="content"
                v-if="bEdit"
                :options="options"
                :optionLabel="optionLabel"
                :optionValue="optionValue"
                @change="onChange"
                :loading="loading"
                optionGroupLabel="label"
                optionGroupChildren="items"
                scrollHeight="400px"
                class="drop"
                style="width: 90%; cursor: default"/>
      <button
          class="p-button p-component p-button-icon-only"
          type="button"
          v-if="bEdit"
          v-on:click="bEdit=false"
      >
        <span class="pi pi-times p-button-icon"></span>
        <span class="p-button-label">&nbsp;</span>
        <span class="p-ink" style="height: 33px; width: 33px; top: 2.8125px; left: -5.14062px;"></span>
      </button>
    </div>
    <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
  </div>
</template>

<script>
import {ref} from 'vue'
import useVuelidate from '@vuelidate/core'
import {helpers, required} from '@vuelidate/validators'

export default {
  name: 'EditDropdownGrouped',
  props: {
    title: {
      type: String,
      default: ''
    },
    selectedInput: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: Object,
    optionLabel: String,
    optionValue: String,
    loading: Boolean,
  },
  data() {
    return {
      bEdit: false,
      selectedCursor: 'pointer',
    }
  },
  setup(props) {
    const content = ref(props.selectedInput);//ref(this.content);//ref(88)
    const mandatoryField = 'Campo obligatorio';
    const rules = {
      content: {
        required: helpers.withMessage(mandatoryField, required),
        $autoDirty: true
      },
    }
    const vv = useVuelidate(rules, {content})
    return {content, vv}
  },
  methods: {
    getValue() {
      let arrOptions = [];
      if (this.options) {
        Object.values((this.options)).forEach((res) => {
          arrOptions = arrOptions.concat(res.items)
        })
      }
      this.calculatedOptions = arrOptions;

      const val = this.calculatedOptions &&
          Object.values(this.calculatedOptions).find(item => item[this.optionValue] === this.selectedInput)
      return val && this.optionLabel && val[this.optionLabel];
    },
    onChange(event) {
      this.$emit('onChange', event);
    }
  }

}
</script>

<style lang="css" scoped>
.main {
  padding: 10px;
}

.field-title {
  color: var(--green-banklot);
  font-weight: bold;
  margin-bottom: 10px;
}

.content {
  margin-left: 10px;
}

.drop {
  height: 33px;
}
</style>